import { Flex, Text, Image, Container } from "@mantine/core";

export const GenderLabFooter = () => {
  return (
    <>
      <Container size={1200}>
        <Flex justify="end" align="center" mt="lg" gap="xs">
          <Text>Con el apoyo del</Text>
          <Image width={100} src="/img/bid-logo.png" />
        </Flex>
      </Container>
    </>
  );
};
