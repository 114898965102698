import { TForwardEmail, TStudentUploadRequest } from "../../types";
import { ApiWithToken } from "../axios-instance";

export const adminService = {
  clients: {
    uri: "/api/administrator/client/",
    get: async () => ApiWithToken.get(`${adminService.clients.uri}`),
    post: async (data: any) =>
      ApiWithToken.post(`${adminService.clients.uri}`, data),

    user: {
      uri: "/api/administrator/user/",
      get: async () => ApiWithToken.get(`${adminService.clients.user.uri}`),
      post: async (data: any) =>
        ApiWithToken.post(`${adminService.clients.user.uri}`, data),
    },
  },
  aula: {
    aulaUri: "/api/administrator/aula/",
    getPublishedCourses: async () =>
      ApiWithToken.get(`${adminService.aula.aulaUri}published_courses/`),
    enrollStudent: async (data: TStudentUploadRequest) =>
      ApiWithToken.post(`${adminService.aula.aulaUri}enroll_student/`, data),
    listEmailMessageLogs: async (page: number, pageSize: number, query?: string) => {
      return ApiWithToken.get(
        `${adminService.aula.aulaUri}email_message_logs/?page=${page}&page_size=${pageSize}&search=${query || ""}`
      );
    }
  },
  forwardEmail: {
    post: async (data: TForwardEmail) =>
      ApiWithToken.post("/api/administrator/forward_email/", data),
  },
  resources: {
    get: async () => ApiWithToken.get("/api/administrator/resources/"),
  },
  plans: {
    get: async () => ApiWithToken.get("/api/administrator/plans/"),
  },
  singleUseServices: {
    get: async () => ApiWithToken.get("/api/administrator/single_use_services/"),
    post: async (data: any) =>
      ApiWithToken.post("/api/administrator/single_use_services/", data),
  },
  singleUseServicesContracts: {
    post: async (data: any) =>
      ApiWithToken.post("/api/administrator/single_use_services_contracts/", data),
    put: async (id: number, data: any) =>
      ApiWithToken.put(`/api/administrator/single_use_services_contracts/?id=${id}`, data),
    delete: async (id: number) =>
      ApiWithToken.delete( `/api/administrator/single_use_services_contracts/?id=${id}`),
  },
  planContracts: {
    post: async (data: any) =>
      ApiWithToken.post(`/api/administrator/plan_contracts/`, data),
    customPlanPost: async (data: any) =>
      ApiWithToken.post(`/api/administrator/plan_contracts/?custom=True`, data),
    put: async (id: number, data: any) =>
      ApiWithToken.put(`/api/administrator/plan_contracts/?id=${id}`, data),
    delete: async (id: number) =>
      ApiWithToken.delete(`/api/administrator/plan_contracts/?id=${id}`),
  },
  contracts: {
    get: async (page: number, pageSize: number, search?: string) =>
      ApiWithToken.get(
        `/api/administrator/contracts/?page=${page}&page_size=${pageSize}&search=${
          search || ""
        }`
      ),
  },
  webinars: {
    getEnrollments: async (searchQuery?: string) =>
      ApiWithToken.get("/api/administrator/webinar_enrollments/", {
        params: {
          search: searchQuery,
        },
      }),
    uploadMaterial: async (id: string, file: any) => {
      const formData = new FormData();
      formData.append("enrollment_id", id);
      formData.append("material", file);
      return ApiWithToken.post(
        "/api/administrator/webinar_enrollments/upload_material/",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    },
    uploadAttendees: async (id: string, file: any) => {
      const formData = new FormData();
      formData.append("enrollment_id", id);
      formData.append("attendees", file);
      return ApiWithToken.post(
        "/api/administrator/webinar_enrollments/upload_attendees/",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    },
  },
  measurementProcess: {
    get: async (page: number, pageSize: number, query?: string) =>
      ApiWithToken.get(
        `/api/administrator/measurement_process/?page=${page}&page_size=${pageSize}&search=${query || ""}`
      ),
  },
  staffSurvey: {
    put: async (id: number, data: any) =>
      ApiWithToken.put(`/api/administrator/staff_survey/?id=${id}`, data),
  },
};
