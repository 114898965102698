import {
  Button,
  Center,
  Flex,
  Modal,
  Pagination,
  Table,
  Tabs,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { GenderLabAppShell, GenderLabPageSkeleton } from "../../components";
import { useEffect, useState } from "react";
import { adminService } from "../../api";
import {
  TAulaEmailLogs,
  TAulaEmailLogsTableData,
  TForwardEmail,
} from "../../types";
import { MailSendButton } from "../../components/EditDeleteButtonsCombo/MailSendButton";
import { useForm } from "@mantine/form";
import { validateEmailField } from "../../utils/validateEmailField";

export const AulaEmailLogsPage = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [emailLogs, setEmailLogs] = useState<TAulaEmailLogsTableData>(null!);
  const [selectedRow, setSelectedRow] = useState<TAulaEmailLogs | null>(null);

  const [selectedTab, setSelectedTab] = useState<string | null>("email-logs");
  const [query, setQuery] = useState("");

  const pageSize = 10;
  const [page, setPage] = useState(1);

  const form = useForm({
    initialValues: {
      additionalRecipient: "",
    },
    validate: {
      additionalRecipient: validateEmailField,
    },
  });

  useEffect((): void => {
    const getPaginatedData = async () => {
      const response = await adminService.aula.listEmailMessageLogs(
        page,
        pageSize
      );
      setEmailLogs(response.data);
    };

    getPaginatedData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect((): void => {
    const getPaginatedData = async () => {
      setPage(1);
      const response = await adminService.aula.listEmailMessageLogs(
        1,
        pageSize,
        query
      );
      setEmailLogs(response.data);
    };
    getPaginatedData();
  }, [query]);

  const onMailSendButtonClick = (row: TAulaEmailLogs) => {
    form.reset();
    setSelectedRow(row);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (selectedRow && !form.validate().hasErrors) {
      const data: TForwardEmail = {
        email_log_id: selectedRow.id,
        ...(form.values.additionalRecipient && {
          additional_recipient: form.values.additionalRecipient,
        }),
      };
      setLoading(true);
      adminService.forwardEmail
        .post(data)
        .then(() => {
          setSelectedRow(null);
          setPage(1);
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  function formatDate(isoString: string): string {
    const date = new Date(isoString);

    // Opciones de formato
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZoneName: "short",
    };

    // Convertir la fecha al formato legible
    return date.toLocaleDateString("en-US", options);
  }
  return (
    <GenderLabAppShell>
      <Title order={2} sx={{ marginBottom: 20 }}>
        Correos Enviados
      </Title>
      <Tabs value={selectedTab} onTabChange={setSelectedTab}>
        <Tabs.List>
          <Tabs.Tab value="email-logs">Email Logs</Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="email-logs" pt="xs">
          <TextInput
            w={400}
            placeholder="Buscar"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
          {!emailLogs ? (
            <GenderLabPageSkeleton />
          ) : (
            <>
              <Table
                striped
                highlightOnHover
                withBorder
                withColumnBorders
                mt="md"
              >
                <thead>
                  <tr>
                    <th>Asunto</th>
                    <th>Destinatario</th>
                    <th>Fecha y Hora</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {emailLogs.data.map((emailLog, idx) => (
                    <tr key={idx}>
                      <td>{emailLog.email_template.description}</td>
                      <td>{emailLog.original_recipient}</td>
                      <td>{formatDate(emailLog.sent_at)}</td>
                      <td>
                        <MailSendButton
                          onClick={(): void => onMailSendButtonClick(emailLog)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Center>
                <Pagination
                  mt="md"
                  total={emailLogs.total_pages}
                  onChange={setPage}
                  color="blue"
                />
              </Center>
            </>
          )}
        </Tabs.Panel>
      </Tabs>
      <Modal
        size="lg"
        opened={selectedRow !== null}
        onClose={(): void => setSelectedRow(null)}
        title={<Title order={4}>Reenviar Email</Title>}
      >
        <form onSubmit={handleSubmit}>
          <Flex gap="sm" direction="column">
            <Text>
              Opcionalmente, puedes enviar una copia de este correo a una
              dirección adicional.
            </Text>
            <TextInput
              placeholder="Correo de receptor adicional"
              {...form.getInputProps("additionalRecipient")}
            />
            <Flex mt="lg" justify="end">
              <Button disabled={loading} type="submit">
                Reenviar
              </Button>
            </Flex>
          </Flex>
        </form>
      </Modal>
    </GenderLabAppShell>
  );
};
