import { Button } from "@mantine/core";
import { BiMailSend } from "react-icons/bi";
import { useAuth } from "../../../context";

type MailSendButtonProps = {
  onClick: () => void;
  disabled?: boolean;
};

export const MailSendButton = ({ onClick, disabled }: MailSendButtonProps) => {
  const { loading } = useAuth();

  return (
    <Button
      compact
      size="xs"
      color="green"
      onClick={onClick}
      variant="outline"
      disabled={loading || disabled}
    >
      <BiMailSend size={20} />
    </Button>
  );
};
