export enum ColorSemaphore {
    green = "green",
    yellow = "yellow",
    red = "red",
}

export interface TStudentTable {
    Correo: string;
    Mensaje: string;
    Estado?: ColorSemaphore;
}
