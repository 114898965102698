import {
  Container,
  createStyles,
  Grid,
  // Header,
  // Image,
  // useMantineTheme,
  // Center,
  Flex,
} from "@mantine/core";
import { ColorSchemeToggle } from "../ColorSchemeToggle/ColorSchemeToggle";

const useStyles = createStyles(() => ({
  header: {
    // display: "flex",
    // justifyContent: "space-between",
    // alignItems: "center",
    // height: "100%",
    height: "150px",
  },
}));

export const LoginHeader = () => {
  // const theme = useMantineTheme();
  const { classes } = useStyles();

  return (
    <Grid align="center" className={classes.header}>
      {/* <Grid.Col span={6}>
        <Container size={600}>
          <Flex justify="flex-start">
            <Image
              alt="logo"
              src={
                theme.colorScheme === "light"
                  ? "/img/genderlab-logo.png"
                  : "/img/genderlab-logo-dark.png"
              }
              width={250}
              fit="contain"
            />
          </Flex>
        </Container>
      </Grid.Col> */}
      <Grid.Col span={12}>
        <Container size={350}>
          <Flex justify="center">
            <ColorSchemeToggle />
          </Flex>
        </Container>
      </Grid.Col>
    </Grid>
  );
};
