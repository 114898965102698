export const validateEmailField = (value: string): string | null => {
  if (!value) {
    return null;
  }

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(value)) {
    return "Email no válido.";
  }

  return null;
};
