import {
    CustomLink,
    FormErrorText,
    GenderLabAppShell,
    ResultadosCard,
  } from "../../components";
  import { useEffect, useState } from "react";
  import {
    Stepper,
    Button,
    Group,
    FileInput,
    Select,
    Table,
    Text,
    Title,
    List,
    Modal,
    Stack,
    Progress,
    ScrollArea,
    Badge,
  } from "@mantine/core";
  import { AiOutlineUpload } from "react-icons/ai";
  import Papa from "papaparse";
  import { useForm } from "@mantine/form";
  import { validateStringField } from "../../utils/validateStringField";
  import { adminService } from "../../api";
  import { ColorSemaphore, EmpresaCliente, TCourse, TStudentParseCSV, TStudentTable, TStudentUploadRequest } from "../../types";
  import { useNavigate } from "react-router-dom";
  import { useDisclosure } from "@mantine/hooks";
  import * as XLSX from 'xlsx';
  import { showNotification } from "@mantine/notifications";
  
  export const AulaAdminPage = () => {
    const [active, setActive] = useState(0);
    const nextStep = () =>
      setActive((current) => (current < 3 ? current + 1 : current));
    const prevStep = () =>
      setActive((current) => (current > 0 ? current - 1 : current));
  
    const GENERIC_UPLOAD_ERROR =
      "Hubo un error al subir los datos. Por favor, ponte en contacto con el equipo tech.";
    const [clientValue, setClientValue] = useState("");
    const [clientId, setClientId] = useState("");
    const [parsedCsvFile, setParsedCsvFile] = useState<Papa.ParseResult<TStudentParseCSV> | null>(null);
    const [csvFileName, setCsvFileName] = useState<string | null>(null);
    const [csvFileProcessed, setCsvFileProcessed] = useState(false);
    const [summary, setSummary] = useState<TStudentTable[]>([]);
  
    const [fieldsFound, setFieldsFound] = useState<{
      email: boolean;
      document: boolean;
      name: boolean;
      job: boolean;
      area: boolean;
      workplace: boolean;
    }>({
      email: false,
      document: false,
      name: false,
      job: false,
      area: false,
      workplace: false,
    });
  
    const [clientDropdownData, setClientDropdownData] = useState<
      { value: string; label: string }[]
    >([]);
    const [coursesDropdownData, setCoursesDropdownData] = useState<
      { value: string; label: string }[]
    >([]);
  
    const form = useForm({
      initialValues: {
        searchValue: "",
        csvFile: new File([], ""),
        course: "",
      },
      validate: {
        searchValue: (value) => validateStringField(value),
        csvFile: (value) => value === null && "Debes subir un archivo.",
        course: (value) => validateStringField(value)
      },
    });
  
    const navigate = useNavigate();
  
    const [
      uploadCsvModaledOpened,
      { open: openUploadCsvModal, close: closeUploadCsvModal },
    ] = useDisclosure(false);
  
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
  
    useEffect(() => {
      const getAndSetClients = async () => {
        const response = await adminService.clients.get();
        const clients: EmpresaCliente[] = response.data;
        setClientDropdownData(
          clients.map((client) => ({
            value: client.id.toString(),
            label: `${client.business_name} - ${client.trade_name}`,
          }))
        );
      };
  
      const getAndSetCourses = async () => {
        const response = await adminService.aula.getPublishedCourses();
        const courses: TCourse[] = response.data;
        setCoursesDropdownData(
          courses.map((course) => ({
            value: course.id.toString(),
            label: course.name,
          }))
        );
      };
  
      getAndSetClients();
      getAndSetCourses();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  
    useEffect(() => {
      if (!csvFileProcessed && form.values.csvFile.name !== "") {
        setCsvFileProcessed(true);
        const csvFile: File = form.values.csvFile;
        setCsvFileName(csvFile.name);
  
        csvFile.text().then((text) => {
          const parsed: Papa.ParseResult<TStudentParseCSV> = Papa.parse(text, {
            header: true,
            delimiter: "",
            transformHeader: (header) => {
              const transformedHeader = header.replace(/\s/g, "");
              return transformedHeader;
            },
          });
          parsed.data = parsed.data.filter(
            (row) => row.email && row.nombre
          );
          setParsedCsvFile(parsed);
  
          const csv = Papa.unparse(parsed.data, { header: true, delimiter: "," });
          form.setFieldValue("csvFile", new File([csv], csvFile.name));
  
          const findHeader = (header: string) =>
            parsed.meta.fields?.find((field) => field === header);
          setFieldsFound({
            email: !!findHeader("email"),
            document: !!findHeader("documento"),
            name: !!findHeader("nombre"),
            job: !!findHeader("puesto"),
            area: !!findHeader("area"),
            workplace: !!findHeader("centro"),
          });
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.values.csvFile]);
  
    const handleNext = () => {
      if (active === 0) {
        if (!form.validateField("searchValue").hasError) {
          nextStep();
        }
      } else if (active === 1) {
        if (
          !form.validateField("csvFile").hasError &&
          Object.values(fieldsFound).every((value) => value)
        ) {
          nextStep();
        }
      } else if (active === 2) {
        if (!form.validateField("course").hasError) {
          nextStep();
        }
      }
    };
  
    const downloadErrors = (errors: TStudentTable[]) => {
      if (errors.length > 0) {
        const worksheet = XLSX.utils.json_to_sheet(errors);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Errores');
    
        const xlsxBlob = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([xlsxBlob], { type: 'application/octet-stream' });
    
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `errors-${clientValue}-${new Date().toISOString()}.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    };
  
    const uploadCsv = async () => {
      if (!parsedCsvFile) return;
      setSummary([]);
  
      let errors: TStudentTable[] = [];
      const totalStudents = parsedCsvFile.data.length;
      setProgress(0);
      setLoading(true);
  
      let rateLimitRemaining: number | null = null;
      let rateLimitReset: number | null = null;
  
      for (let index = 0; index < totalStudents; index++) {
        const studentData = parsedCsvFile.data[index];
        const courseId = form.values.course;
  
        try {
          let data: TStudentUploadRequest = {
            student: {
              name: studentData.nombre,
              email: studentData.email,
              document_id: studentData.documento,
              job: studentData.puesto,
              area: studentData.area,
              workplace: studentData.centro,
              client: Number(clientId),
            },
            course_id: Number(courseId),
          };
          if (rateLimitRemaining && rateLimitReset) {
            data.rate_limit = {
              "ratelimit-remaining": rateLimitRemaining,
              "ratelimit-reset": rateLimitReset,
            };
          }
          const response = await adminService.aula.enrollStudent(data);
          rateLimitRemaining = parseInt(response.data["ratelimit_remaining"]);
          rateLimitReset = parseInt(response.data["ratelimit_reset"]);
          setSummary((prevSummary) => [
            ...prevSummary,
            {
              Correo: studentData.email,
              Mensaje: response.data.message as string,
              Estado: ColorSemaphore.green,
            },
          ]);
        } catch (error: any) {
          setSummary((prevSummary) => [
            ...prevSummary,
            {
              Correo: studentData.email,
              Mensaje: error.response?.data?.message || GENERIC_UPLOAD_ERROR,
              Estado: error.response?.status === 403 ? ColorSemaphore.yellow : ColorSemaphore.red,
            },
          ]);
          if (error.response?.status === 400 || error.response?.status === 500) {
            errors.push({
              Correo: studentData.email,
              Mensaje: error.response?.data?.message || GENERIC_UPLOAD_ERROR,
            });
          }
        }
  
        setProgress(((index + 1) / totalStudents) * 100);
      }
  
      setLoading(false);
  
      downloadErrors(errors);
    };
  
    const handleClientChange = (value: string) => {
      setClientValue(value);
  
      const selectedClient = clientDropdownData.find(
        (client) => client.label === value
      );
  
      if (selectedClient) {
        setClientId(selectedClient.value);
      }
    };
  
    const preventClose = () => {
      if (loading) {
        showNotification({
          title: "Atención",
          message:
            "Debes esperar a que termine la carga de estudiantes ☝️.",
          color: "red",
        });
      }
      else {
        closeUploadCsvModal();
        if (summary.length > 0) {
          navigate(0);
        }
      }
    };
  
    return (
      <GenderLabAppShell>
        <Title order={2} sx={{ marginBottom: 20 }}>
          Subir estudiantes
        </Title>
        <Stepper active={active} breakpoint="sm">
          <Stepper.Step label="Paso 1" description="Seleccionar empresa cliente">
            <Select
              label="Busca aquí a la empresa cliente"
              searchable
              onSearchChange={handleClientChange}
              searchValue={clientValue}
              nothingFound="No se encontró la empresa cliente"
              data={clientDropdownData}
              withAsterisk
              disabled={clientDropdownData.length === 0}
              {...form.getInputProps("searchValue")}
            />
          </Stepper.Step>
          <Stepper.Step label="Paso 2" description="Subir .csv con estudiantes">
            <Stack spacing="md">
              <FileInput
                label="Sube aquí el archivo .csv con los estudiantes"
                withAsterisk
                accept=".csv"
                icon={<AiOutlineUpload />}
                {...form.getInputProps("csvFile")}
                onChange={(file) => {
                  if (file) {
                    form.setFieldValue("csvFile", file);
                    setCsvFileProcessed(false);
                  }
                }}
              />
              <Text color="dimmed">
                Puedes encontrar el template{" "}
                <CustomLink
                  to="https://docs.google.com/spreadsheets/d/15XuOD1qfL0A0TM3HsRrn2qFkNDTH0DqLixhP0lmEQpI/edit?usp=sharing"
                  target="_blank"
                  fontSize={16}
                >
                  aquí
                </CustomLink>
                .
              </Text>
            </Stack>
          </Stepper.Step>
          <Stepper.Step label="Paso 3" description="Seleccionar cursos">
            <Select
              label="Selecciona aquí el curso"
              data={coursesDropdownData}
              withAsterisk
              {...form.getInputProps("course")}
            />
          </Stepper.Step>
          <Stepper.Completed>
            <ResultadosCard title="Resumen">
              <List>
                <List.Item>
                  <Text>
                    <b>Empresa cliente</b>:{" "}
                    {
                      clientDropdownData.find(
                        (client) => client.value === form.values.searchValue
                      )?.label
                    }
                  </Text>
                </List.Item>
                <List.Item>
                  <Text>
                    <b>Archivo .csv</b>: {csvFileName}
                  </Text>
                </List.Item>
                <List.Item>
                  <Text>
                    <b>Cursos</b>:{" "}
                    <List>
                      {coursesDropdownData
                        .filter((course) => {
                          return course.value === form.values.course;
                        })
                        .map((course) => (
                          <List.Item key={course.value}>{course.label}</List.Item>
                        ))}
                    </List>
                  </Text>
                </List.Item>
              </List>
            </ResultadosCard>
          </Stepper.Completed>
        </Stepper>
  
        <Group position="center" mt="xl">
          <Button variant="default" onClick={prevStep}>
            Atrás
          </Button>
          {active < 3 ? (
            <Button onClick={handleNext}>Siguiente paso</Button>
          ) : (
            <Button onClick={openUploadCsvModal}>Subir</Button>
          )}
        </Group>
  
        {active === 1 && parsedCsvFile && (
          <ResultadosCard sx={{ marginTop: 16 }}>
            <Table mt="xs" striped highlightOnHover withBorder withColumnBorders>
              <thead>
                <tr>
                  {parsedCsvFile.meta.fields?.map((field, index) => (
                    <th key={index}>{field}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {parsedCsvFile.data.slice(0, 5).map((row, index) => (
                  <tr key={index}>
                    {Object.values(row).map((value, index) => (
                      <td key={index}>{value}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
            <Text mt="md" fz="xs" color="dimmed">
              Primeras cinco filas del archivo.
            </Text>
            {fieldsFound.email &&
            fieldsFound.document &&
            fieldsFound.name &&
            fieldsFound.job &&
            fieldsFound.area &&
            fieldsFound.workplace ? null : (
              <FormErrorText>
                El encabezado debe tener las columnas{" "}
                <i>email, documento, nombre, puesto, area y centro</i>. Revisa
                también que no hayan espacios en blanco en el encabezado.
              </FormErrorText>
            )}
          </ResultadosCard>
        )}
        <Modal
          size={"xl"}
          opened={uploadCsvModaledOpened}
          onClose={preventClose}
          closeOnClickOutside={false}
          title={loading ? "Subiendo datos..." :
            summary.length === 0 ? "¿Estás seguro/a de que quieres subir estos datos?" :
            "Carga terminada"
          }
        >
          {(!loading && summary.length === 0) && (
            <Group position="center" mt="xl">
              <Button onClick={uploadCsv} disabled={loading}>
                Sí
              </Button>
            </Group>
          )}
          {(loading || summary.length > 0) && (
            <Progress
              label={Math.round(progress) + "%"}
              value={progress < 10 ? 10 : progress}
              color={"blue"}
              radius="xl"
              size={18}
              style={{ marginTop: 20 }}
            />
          )}
          {summary.length > 0 && (
            <ScrollArea h={300} style={{ marginTop: 20 }}>
              <Table striped withBorder>
                <thead>
                  <tr>
                    <th style={{ width: "30%" }}>Correo</th>
                    <th>Mensaje</th>
                    <th>Estado</th>
                  </tr>
                </thead>
                <tbody>
                  {summary.map((row: TStudentTable, index: number) => (
                    <tr key={row.Correo + index}>
                      <td>{row.Correo}</td>
                      <td>{row.Mensaje}</td>
                      <td>
                        <Badge
                          color={row.Estado}
                        >
                          {(() => {
                            if (row.Estado === ColorSemaphore.green) return "Éxito";
                            if (row.Estado === ColorSemaphore.yellow) return "Advertencia";
                            return "Error";
                          })()}
                        </Badge>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </ScrollArea>
          )}
        </Modal>
      </GenderLabAppShell>
    );
  };
  