export const onChangeCleanFields = (
  value: string,
  field: string,
  fieldsToReset: string[],
  condition: string,
  form: any
) => {
  form.setFieldValue(field, value);
  if (value === condition) {
    fieldsToReset.forEach((field) => {
      form.setFieldValue(field, "");
    });
  }
};
